import React from 'react';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import sitemap from '../../data/sitemap';
import Hero from './Hero';
import Sectors from './Sectors';
import FirstSection from './FirstSection';
import Mobility from './Mobility';
import SecondSection from './SecondSection';
import ContactUs from './ContactUs';
import CallToAction from '../CallToAction';


export default ({ seo, location }) => (
  <Layout overlay page={sitemap.solutions}
    url='_url:solutions'>
    <SEO {...seo} location={location}/>
    <Hero />
    <Sectors />
    <FirstSection />
    <Mobility />
    <SecondSection />
    <ContactUs />
    <CallToAction/>
  </Layout>
)