import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import i18n from '../../../locale';
import RightImageSection from '../../../components/RightImageSection';
import LeftImageSection from '../../../components/LeftImageSection';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic3: imageSharp(fluid: {originalName: {eq: "solutions_03_efficiency.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic4: imageSharp(fluid: {originalName: {eq: "solutions_04_drivers.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic5: imageSharp(fluid: {originalName: {eq: "solutions_05_fleet.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic6: imageSharp(fluid: {originalName: {eq: "solutions_06_safety.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic7: imageSharp(fluid: {originalName: {eq: "solutions_07_monitoring.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic8: imageSharp(fluid: {originalName: {eq: "solutions_08_ev.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic3, pic4, pic5, pic6, pic7, pic8 }) => (
      <>
        <LeftImageSection
          image={pic3}
          title={i18n('Operational efficiency')}
          subtitle={[
            i18n('Increasing the operational efficiency of a fleet is always a topic of high importance and relevant to improving the total cost of ownership (TCO). Many aspects could help to be more efficient - vehicle health status, driving behaviour, preventive actions due to early alerts, fuel management, and many more.'),
            i18n('At Traffilog, we deliver solutions to different sizes and types of fleets. Some of the most common solutions are the gathering of real-time data and detailed notifications sent to fleet managers via the web or mobile applications or BI reporting. Another great example is driver feedback either in real-time via the smart driver maneuver awareness system on the vehicle’s dashboard or the use of historical reports showing the drivers how they can improve.')
          ]}
          id={i18n('_hash:operational-efficiency')}
        />

        <RightImageSection
          image={pic4}
          title={i18n('Driver management')}
          subtitle={[
            i18n('Depending on the level of requirements, driver management could have different definitions and the solutions will vary. Driver identification is one of the most common requirements where afterward there could be enabled other options on top to expand the driver management overview.'),
            i18n('One example of those options is a full overview of the historical performance by measuring the number of acceleration, braking, and turning events conducted by a driver, as well as the severity of the events and the distance traveled, Traffilog can build up a clear picture of driver’s efficiency and safety.'),
            i18n('This could lead to enabling driver education and compensation to promote efficient driving or identifying drivers who may need more training behind the wheel. Another example is to increase safety awareness with the goal to reduce accident risks and empower drivers via better engagement.')
          ]}
          id={i18n('_hash:driver-management')}
        />

        <LeftImageSection
          image={pic5}
          title={i18n('Fleet administration')}
          subtitle={[
            i18n('Fleet administration combines all tasks of a fleet owner/manager, from selecting the vehicles that fit the operations, operating these on a daily basis, and de-fleeting those at some point with all required documentation attached.'),
            i18n('Throughout all that time, there is a need to complete mandatory vehicle-related tasks like service and maintenance, fuel management, safety checklists, managing responsibilities of employees, tracking usage, and more.'),
            i18n('Managing fleet operations should be easier and more convenient, therefore, at Traffilog we provide several useful options for digital documentation, communication, checklists, and more.'),
            i18n('Having all vehicle documents like leasing or insurance agreements come in handy when an unexpected situation occurs. Having information in a digital format helps to keep track and make informed decisions as well as share internally or with potential buyers.'),
          ]}
          id={i18n('_hash:fleet-administration')}
        />

        <RightImageSection
          image={pic6}
          title={i18n('Increased the safety of drivers and vehicles')}
          subtitle={[
            i18n('Ensure peace of mind by knowing that you have secured optimal driver safety and operate vehicles that are safe to drive. Traffilog delivers solutions on multiple levels - from remote alert management to vehicle immobilization. Remote alerts are common to stay informed about issues that occur and require actions.'),
            i18n('These alerts could be related to geofencing, driver safety events, mechanical issues, or others. Some alerts could trigger automated flows like reporting an issue to an assigned workshop to recuse repair costs or sending a collision detection report to damage control to reduce insurance costs.'),
            i18n('The more advanced safety measures could include remote vehicle immobilization to prevent unauthorized vehicle usage, or the ability to lock/unlock vehicles based on certain conditions related to the vehicle’s state, location, or other.')
          ]}
        />

        <LeftImageSection
          image={pic7}
          title={i18n('Advanced vehicle monitoring')}
          subtitle={[
            i18n('Advanced vehicle monitoring refers to the possibility to establish a remote link to vehicles 24/7 to enable multiple solutions for optimising daily operations, keeping a historical record of vehicle utilisation, remote assistance, integrated monitoring, and more.'),
            i18n('The traditional and most commonly referred to use case is vehicle tracking. However, tracking is only one of the many possible use cases and also the simplest one where vehicles’ location can be followed in real-time or recorded in history for trip logging, reporting, analytics, alerts, etc.'),
            i18n('Larger and more complex organizations require greater flexibility to run their operations. Therefore, a more suitable approach is the concept of integrated monitoring which aims to assist such customers to design a data-driven service system that adapts to their needs and enables more links, hence increasing the value of a solution.'),
          ]}
          id={i18n('_hash:advanced-monitoring')}
        />

        <RightImageSection
          image={pic8}
          title={i18n('Transition to EV operations')}
          subtitle={[
            i18n('The transition to electric vehicles is new to almost every business or person. It has its characteristics and specific needs when selecting the right EVs, educating drivers, or optimising operations.'),
            i18n('One of the most common concerns for businesses is range anxiety, or in other words, knowing whether they can perform their business as usual after they switch to EVs. Deciding this requires all possible considerations in advance and having all the required data to validate it.'),
            i18n('Traffilog can assist in the transition process at any level, from planning and simulating the experience based on real-world data in different use cases, to running and optimising daily operations for EV fleets.')
          ]}
          id={i18n('_hash:transition-to-evs')}
        />

      </>
    )}/>
);