import React from 'react';
import Img from "gatsby-image/withIEPolyfill";
import { Grid } from '@material-ui/core';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default ({ image, text}) => (
    <Grid item container xs={12} sm={6} md={2} lg={2} xl={2}
    alignContent='center'
    alignItems='center'
    justifyContent='flex-start'
    className={styles.toolWrap}>
        <div className={styles.itemImageContainer}>
            <Img imgStyle={{ objectFit: 'contain' }} 
                objectPosition='center center'     
                objectFit='contain'
                className={styles.itemImage}
                fluid={image.fluid}
                alt={text}/>
        </div>
        <Typography variant='subtitle2'
            weight='light'
            align='left'
            className={styles.itemSubtitle}>
            {text}
        </Typography>
    </Grid>
);