import React from "react";
import Content from '../views/Solutions';

export default ({location}) => (
  <Content location={location} seo={{
    title: "Transform Fleet Management Operations with Traffilog's Advanced Connected Vehicle Solutions",
    lang: 'en',
    description: "Maximize fleet performance and efficiency with Traffilog's connected vehicle solutions. Our telematics technology offers GPS vehicle tracking and real-time fleet optimization for mobility operators. Try it today!",
    meta: [{
      name: 'keywords',
      content: 'Connected vehicle solutions, fleet management, telematcis, GPS vehicle tracking, fleet optimization',
    }]
  }}/>
);