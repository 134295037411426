import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { Grid } from '@material-ui/core';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import SingleItem from './SingleItem';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image1: imageSharp(fluid: { originalName: {eq: "solutions_02_icon01.png"} }) {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image2: imageSharp(fluid: { originalName: {eq: "solutions_02_icon02.png"} }) {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image3: imageSharp(fluid: { originalName: {eq: "solutions_02_icon03.png"} }) {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image4: imageSharp(fluid: { originalName: {eq: "solutions_02_icon04.png"} }) {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        image5: imageSharp(fluid: { originalName: {eq: "solutions_02_icon05.png"} }) {
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image1, image2, image3, image4, image5 }) => {
      return (<SectionWrapper className={styles.container} id={i18n('_hash:fleet-management')}>
          <Typography variant='h2'
            weight='bold'
            className={styles.titleContainer}>
            {i18n('Fleet management solutions across sectors')}
          </Typography>

          <Grid container justifyContent='center' alignContent='center' alignItems='center'
            className={styles.toolsContainer}>
              <SingleItem image={image1} text={i18n('Public transportation')} />
              <SingleItem image={image2} text={i18n('Car sharing')} />
              <SingleItem image={image3} text={i18n('Delivery and logistics')} />
              <SingleItem image={image4} text={i18n('Construction')} />
              <SingleItem image={image5} text={i18n('Municipal and state services')} />
          </Grid>
                      
      </SectionWrapper>
  )}}/>
);