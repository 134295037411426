import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Button from '../../../components/Button';
import i18n from '../../../locale';
import SectionWrapper from '../../../components/SectionWrapper';
import Typography from '../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: imageSharp(fluid: { originalName: {eq: "solutions_16_contact.jpg"}}) {
          fluid(maxHeight: 856, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ image }) => {
      return (
      <SectionWrapper className={styles.container}>
          <div className={styles.innerContainer} style={{ backgroundImage: `url(${image.fluid.src})`}}>
            <Typography variant='h2'
              weight='bold'
              className={styles.titleContainer}>
              {i18n('Any other interests or use cases?')}
            </Typography>
            <Typography variant='subtitle2'
              weight='light'
              className={styles.subtitleContainer}>
              {i18n('Let’s work together to explore your project')}
            </Typography>
            <div className={clsx(styles.titleContainer, styles.buttonContainer)}>
              <Link to={i18n('_url:lets-connect')} style={{ textDecoration: 'none'}}>
                <Button className={styles.button}>
                  {i18n('Contact us')}
                </Button>
              </Link>
            </div> 
          </div>          
      </SectionWrapper>
  )}}/>
);