import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import i18n from '../../../locale';
import RightImageSection from '../../../components/RightImageSection';
import LeftImageSection from '../../../components/LeftImageSection';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pic10: imageSharp(fluid: {originalName: {eq: "solutions_10_keyless.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic11: imageSharp(fluid: {originalName: {eq: "solutions_11_collision.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic12: imageSharp(fluid: {originalName: {eq: "solutions_12_uptime.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic13: imageSharp(fluid: {originalName: {eq: "solutions_13_providers.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic14: imageSharp(fluid: {originalName: {eq: "solutions_14_video.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
        pic15: imageSharp(fluid: {originalName: {eq: "solutions_15_bi.png"}}) {
          fluid(maxHeight: 800, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    `}
    render={({ pic10, pic11, pic12, pic13, pic14, pic15 }) => (
      <>
        <LeftImageSection
          image={pic10}
          title={i18n('Keyless access')}
          subtitle={[
            i18n('New mobility services aim to deliver better customer experiences. One of the first steps in planning those experiences is to consider how a customer can access and use the vehicle without having a key.'),
            i18n('One example of a solution with keyless access that Traffilog has delivered is contactless shared vehicle services for car sharing, rental, and car subscription services implemented in different user applications or via APIs. Another example is the flexibility to secure remote control of vehicles for leasing and car subscription partners by enabling secure remote lock in cases of customer misconduct.'),
            i18n('Due to the shared use case, ensuring the proper management of company fleets is also a good example of securing better operations after implementing keyless access.  For instance, enabling only a specific employee to unlock a vehicle when booking a company car.'),
          ]}
          id={i18n('_hash:keyless-access')}
        />

        <RightImageSection
          image={pic11}
          title={i18n('Collision detection')}
          subtitle={[
            i18n('Collision detection is one of the solutions which we hope our partners would never use but is great to have in place in case an accident occurs. The system can detect collisions of different magnitudes, from the smallest bumps in a parking lot to a major accident. After a collision is detected, the system sends a detailed accident report to internal or external recipients specified by the operator or triggers a pre-defined flow.'),
            i18n('Collision detection provides a different dimension when dealing with risk management in the mobility space. The ownership is not personal, hence the risk is spread across the stakeholders. In shared mobility, very often the small damages are overlooked by the driver. This creates a major issue for the operators and often leads to a poor customer experience for the next one.'),
            i18n('Furthermore, increasing safety creates a positive customer impression, while it reduces costs.')
          ]}
          id={i18n('_hash:collision-detection')}
        />

        <LeftImageSection
          image={pic12}
          title={i18n('Maximize uptime and revenue')}
          subtitle={[
            i18n('Leveraging data to maximise uptime and revenue should be on top of the to-do list for every fleet and mobility operator. Depending on the issue at hand, there could be several aspects to consider ranging from scheduling and automating regular maintenance tasks like the change of tires, charging/fueling, to preventive and predictive alerts like early detection of user red flags, malfunctions, and more complex implementation like enabling dynamic pricing or pay-as-you-go options.'),
            i18n('All of the above solutions are unique to the operations of every partner. However, the results could lead to a significant competitive advantage when it comes to operational efficiency, proactiveness, and high customer satisfaction, among some examples. While solving issues at hand, many partners also find new ways to bring innovative ideas to life.'),
          ]}
          id={i18n('_hash:maximize-uptime')}
        />

        <RightImageSection
          image={pic13}
          title={i18n('Manage service providers')}
          subtitle={[
            i18n('In today’s online-dominated world, people are increasing expectations of their service providers. Therefore, integrating vehicle data into daily operations will soon be a requirement for any modern service provider. Workshops, roadside assistance, insurance, parking, fuel/charge management, and other service providers are adapting to the needs of fleet and mobility operators to secure their role in the new data-driven environment.'),
            i18n('There are multiple ways to manage the links to service providers and automate processes to ensure value-added services to create a better experience for customers and drivers. Traffilog is taking a different role in setting up such links depending on the project requirements and unique experience that our partners want to create.'),
          ]}
        />

        <LeftImageSection
          image={pic14}
          title={i18n('Video telematics')}
          subtitle={[
            i18n('Video telematics provides an additional layer of data and it is mostly used to create a more complete view of certain events. By using one or more cameras placed in the vehicle, operators can receive immediate video feedback before, during, and after specific events to assess why those events occurred.'),
            i18n('There are multiple options to utilize video telematics as well as specific guidelines in place to make sure that it is the right choice for partners. Although many people may not be aware of this, video footage is actually overwritten on the device itself for most of the time and it is never saved. The footage is transmitted only when specific events occur and only for the seconds before, during, and after those.'),
            i18n('Video telematics may be a good choice for businesses dealing with a high number of insurance claims, disputes, or operations in high-risk areas.'),
          ]}
        />

        <RightImageSection
          image={pic15}
          title={i18n('Business Intelligence (BI)  reporting and tools')}
          subtitle={[
            i18n('Traffilog’s BI reporting could take different forms, from sending standalone reports via email to automated white label reports based on multiple internal and external data sources.'),
            i18n('The objective when designing the right solution is finding the optimal way to help partners and customers make more data-driven decisions and improve business performance. As in a usual BI reporting, we assess the individual needs of a partner or customer and prepare tailored reporting flows to fit the established operations.'),
            i18n('BI reporting is a great choice when actionable insights or interactive analytics tools are requested as an integrated part of the solution. The idea is to provide everyone the opportunity to explore reports and get results with ease.'),
          ]}
        />

      </>
    )}/>
);